import * as React from "react"

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const CookieDeclarationPage = () => {
  return (
    <main style={pageStyles}>
      <script id="CookieDeclaration" src="https://consent.cookiebot.com/9e844bf2-4851-4cf5-aa9c-10f66f1e492a/cd.js" type="text/javascript" async></script>
    </main>
  )
}

export default CookieDeclarationPage
